
.custom-button-wrapper {
  border-radius: 24px;
  background: #020206;
  backdrop-filter: blur(20px);

  &:hover {
    cursor: pointer;
  }
}

.custom-button-container {
  margin: 0 24px;
}

.custom-button-text {
  margin: auto;
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
