
.not-found-container {
  min-width: 390px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #11121B;
    z-index: -1;
  }
}

.not-found-background-img {

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: url('../../../public/background.png') no-repeat center;
    mix-blend-mode: luminosity;
    background-size: cover;
    z-index: -1;
  }
}

.not-found-container-background-color {

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.35;
    background: #0B0D10;
    z-index: -1;
  }
}

.coming-soon-center-block-header {
  max-width: 70vw;
}

.not-found-title {
  color: #CEFF32;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 183.841px;
  font-style: normal;
  font-weight: 900;
  line-height: 234.908px; /* 127.778% */
  letter-spacing: -3.677px;
}

.not-found-description {
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  line-height: 130px;
}

.not-found-sub-description {
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 200;
  line-height: 130%; /* 39px */
}

@media only screen and (max-width: 768px) {
  .not-found-title {
    font-size: 90px;
    line-height: unset;
  }

  .not-found-description {
    font-size: 40px;
    line-height: unset;
  }

  .not-found-sub-description {
    font-size: 20px;
    line-height: 30px;
  }
}
