
.work-item-container {
  border-radius: 32px;
  background: rgba(32, 34, 37, 0.85);
  backdrop-filter: blur(20px);
  min-height: 104px;
  padding: 24px;
  gap: 24px;

  &:hover {
    background: #FFFFFF;
    color: #020206;
  }

  &:hover > .work-item > .work-info > .work-title {
    color: #020206;
  }

  &:hover > .work-item > .work-info > .work-secondary-info > .work-category {
    color: #020206;
  }

  &:hover > .work-item > .estimated-cost > .usd-cost {
    color: #020206;
  }
}

.work-item {
  min-height: 56px;
  gap: 24px;
}

.work-item > .icon {
  min-height: 56px;
  min-width: 56px;
  height: 56px;
  width: 56px;
}

.work-item > .icon > svg {
  height: 100%;
}

.work-item > .icon.contract {
  border-radius: 24px;
  background: rgba(255, 114, 69, 0.15);
}

.work-item > .icon.invite {
  border-radius: 24px;
  background: rgba(166, 96, 255, 0.15);
}

.work-item > .icon.invite > svg > path {
  fill: #A660FF;
}

.work-title {
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 22px;
}

.contract-red-color {
  color: #FF7245;
}

.proposal-green-color {
  color: #C3FF01;
}

.job-green-color {
  color: #C3FF01;
}

.invite-purple-color {
  color: #A660FF;
}

.work-secondary-info {
  gap: 20px;
}

.work-datetime {
  color: #77828C;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.work-secondary-info {
}

.estimated-cost {
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  gap: 14px;
}

.cost-text {
  color: #77828C;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.work-info {
  gap: 20px;
}
