
.activate_account_form_body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 56px;
  border-radius: 24px;
  border: 2px solid rgba(2, 2, 6, 0.60);
  backdrop-filter: blur(20px);
  margin-bottom: 32px;
}

.activate_account_form_body_content {
  text-align: center;
}

.activate_account_form_body_content > span {
  color: #C3FF01;
}