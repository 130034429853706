
.pilot-industry {
  color: #C3FF01;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.pilot-item-container {
  border-radius: 32px;
  background: rgba(32, 34, 37, 0.85);
  backdrop-filter: blur(20px);
  min-height: 104px;
  padding: 24px;
  gap: 24px;

  &:hover {
    background: #FFFFFF;
    color: #020206;
  }

  &:hover > .work-secondary-info > .work-item > .work-info > .work-title {
    color: #020206;
  }

  &:hover > .work-secondary-info > .work-item > .work-info > .work-secondary-info > .pilot-industry {
    color: #020206;
  }
}

