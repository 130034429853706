
.home_header {
  display: flex;
  justify-content: space-between;
  padding: 35px;
  gap: 54px;
}

.mock_logo {
  width: 68px;
  height: 40px;
}

.header__text {
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  max-width: 160px;
}

.header__text__container {
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.find_pilot {
  background-color: #FFFFFF;
  border-radius: 16px;
  height: 40px;
  max-width: 160px;
}

.get_hired {
  text-wrap: nowrap;
  color: #FFFFFF;
}

.home-header-logo {
  gap: 6px;
  cursor: pointer;
}

.home-header-logo > svg {

  &:last-child {
    margin: auto 0;
  }
}

@media only screen and (max-width: 560px) {
  .text-logo-svg {
    display: none;
  }
}
