@import './typography.scss';

html {
  //overflow-y: scroll;
  min-height: 100vh;
}

body {
  margin: 0;
  min-height: 100vh;
  //overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  all: unset;
}

.mb-8 {
  margin-bottom: 8px;
}

.mt-8 {
  margin-top: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.mt-72 {
  margin-top: 72px;
}

.error_border {
  border: red 1px solid;
}

.br-24 {
  border-radius: 24px;
}

.br-32 {
    border-radius: 32px;
}

.invalid-feedback {
  color: red;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;
}

.Toastify__toast-container {
  height: 56px;
}

.Toastify__close-button {
  margin: auto;
}

.Toastify__close-button > svg {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Toastify__close-button > svg > path {
  fill: #FFFFFF;
}

.Toastify__progress-bar-theme--light {
  background: #C3FF01;
}

.Toastify__toast-theme--light {
  background: #020206;
  backdrop-filter: blur(20px);
}

.Toastify__toast-body > div {
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Toastify__toast-icon > svg > path {
  fill: #C3FF01;
}

.cursor-pointer {
  cursor: pointer;
}

.select__control {
  background: rgba(2, 2, 6, 0.60) !important;
  backdrop-filter: blur(20px) !important;
  border-radius: 24px !important;
  border: none !important;
  overflow: hidden !important;

  &:hover {
    border-color: #77828D !important;
  }
}

.select__multi-value {
  border-radius: 16px !important;
  background: #77828D !important;
  height: 40px !important
}

.select__multi-value__label {
  color: #FFF !important;
  text-align: center !important;
  leading-trim: both !important;
  text-edge: cap !important;
  font-family: Urbanist, serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  margin: auto !important;
  padding-left: 16px !important;
}

.select__placeholder, .select__option, .select__menu-notice, .select__input, .select__single-value {
  color: #77828C !important;
  leading-trim: both !important;
  text-edge: cap !important;
  font-family: Urbanist, serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.select__value-container > div {
  width: unset !important;
}

.select__multi-value__remove {
  height: 50% !important;
  border-radius: 2px !important;
  padding-right: unset !important;
  padding-left: unset !important;
  margin: auto 4px !important;
  margin-right: 8px !important;
}

.select__menu {
  background: rgba(2, 2, 6) !important;
  backdrop-filter: blur(20px) !important;
  border-radius: 24px !important;
  border: 1px solid #77828D !important;
  margin-top: 4px !important;
  overflow: hidden !important;
}

.select__option {
  background: rgba(2, 2, 6) !important;
  backdrop-filter: blur(20px) !important;
  border: none !important;
  color: #FFFFFF !important;
  padding: 8px 16px !important;
  height: 56px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;

  &:hover {
    background: #4E4E50 !important;
  }

  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: calc(100% - 30px);
    left: 0;
    top: 0;
    margin: 0 15px;
    border-bottom: 1px solid #808082;
  }

  &:last-child:after {
    display: none;
  }
}

.css-t3ipsp-control {
  box-shadow: 0 0 0 1px #77828D !important;
}

.css-13cymwt-control {

  &:hover {
    box-shadow: 0 0 0 1px #77828D !important;
  }
}

.select__input {
  color: #FFF !important;
  height: 44px !important;
}

.select__menu-list {
  padding: 0 !important;
}

.select__option > div > svg, .select__single-value > div > svg {
  margin-right: 16px;
  margin-left: 6px;
}

.select__single-value {
  color: #FFFFFF !important;
}

.content-empty {
  content: '';
}

.mb-24 {
  margin-bottom: 24px;
}

.h-72 {
  height: 72px;
}

.gap-8 {
  gap: 8px;
}

.gap-16 {
  gap: 16px;
}

.pl-0 {
  padding-left: 0;
}

#background-map > .mapboxgl-control-container > .mapboxgl-ctrl-bottom-right {
  display: none;
}

.mapboxgl-ctrl-attrib, .mapboxgl-ctrl-logo, .mapboxgl-ctrl-scale {
  display: none !important;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
  background-image: url("../resources/icons/geolocate.svg") !important;
  background-repeat: no-repeat;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
   background-image: url("../resources/icons/plus.svg") !important;
  background-repeat: no-repeat;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
  background-image: url("../resources/icons/minus.svg") !important;
  background-repeat: no-repeat;
}

.mapboxgl-ctrl-bottom-right {
  width: 72px;
  height: 200px;
}

.mapboxgl-ctrl-geolocate, .mapboxgl-ctrl-zoom-in, .mapboxgl-ctrl-zoom-out {
  border-radius: 24px !important;
  border-color: transparent !important;
  background: rgba(2, 2, 6, 0.60) !important;
  backdrop-filter: blur(20px) !important;
  transition: .2s;

    &:hover {
        background: #4E4E50 !important;
    }
}

.mapboxgl-ctrl-geolocate, .mapboxgl-ctrl-zoom-in, .mapboxgl-ctrl-zoom-out {
  width: 56px !important;
  height: 56px !important
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
  background: transparent !important;
  margin: 0 !important;
}

.mapboxgl-ctrl-group, .mapboxgl-ctrl-group:not(:empty) {
  box-shadow: unset !important;
}

.mapboxgl-ctrl, .mapboxgl-ctrl-group {
  width: 100% !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-group > button {
  margin: 8px auto !important;
}

.mapboxgl-ctrl.mapboxgl-ctrl-group > .mapboxgl-ctrl-zoom-in {
  margin-top: 0 !important;
}

.mapboxgl-ctrl-bottom-right {
  border-radius: 32px 0 !important;
  background: rgba(255, 255, 255, 0.05) !important;
  backdrop-filter: blur(20px) !important;
}

.green-text {
  color: #C3FF01 !important;
}

.mt-16 {
  margin-top: 16px;
}

.mh-90 {
  max-height: 90%;
}

.no-scrollbar {
  scrollbar-width: none;
}

.pl-24 {
  padding-left: 24px !important;
}

.h-56 {
  height: 56px;
}

.ml-24 {
  margin-left: 24px;
}

.pr-0 {
  padding-right: 0;
}

.pr-12 {
  padding-right: 12px;
}

.pt-24 {
  padding-top: 24px;
}

.pr-24 {
  padding-right: 24px;
}

.mr-24 {
  margin-right: 24px;
}

.mt-104 {
  margin-top: 104px;
}

.w-200 {
  width: 200px !important;
  max-width: 200px !important;
}

.w-300 {
  width: 300px !important;
  max-width: 300px !important;
  min-width: 300px !important;
}

.gap-24 {
  gap: 24px !important;
}

.w-400 {
  width: 400px;
  max-width: 400px;
}

.gap-48 {
  gap: 48px;
}

.mb-48 {
  margin-bottom: 48px;
}

.mt-16 {
  margin-top: 16px;
}

.z-4 {
  z-index: 4 !important;
}
