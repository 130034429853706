
.forgot_password_form {
  color: #FFF;
  text-align: right;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  background: rgba(2, 2, 6, 0.25);
  backdrop-filter: blur(10px);
  margin: auto;
}

.forgot_password_content {
  padding: 48px;
}

.forgot_password_header {
  position: relative;
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-bottom: 48px;
}

.forgot_password_input {
  margin-bottom: 32px;
}

.forgot_password_header_icon_container {
}
