
.notification-item {
  padding: 24px;
}

.notification-item > .icon > img {
  border-radius: 24px;
}

.notification-item > .content > .title,
.notification-item > .content > .description {
  color: #FFF;
  text-align: left;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.notification-item > .content > .description {
  font-weight: 500;
  line-height: 22px;
}

.notification-svg {
  height: 56px;
  width: 56px;
  border-radius: 24px;
  background: rgba(195, 255, 1, 0.15);
}

.notification-svg > svg {
  height: 100%;
}
