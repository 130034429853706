
.forgot_password_header_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 0;
  transform: rotate(180deg);
  cursor: pointer;
  height: 16px;
  width: 18px;
  bottom: 30%;
}

.forgot_password_header_icon > path {
  fill: #FFFFFF;
}
