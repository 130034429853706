
.main-page-container {
  padding: 26px 24px;
  background: rgb(1, 1, 1, 0.5);
  min-width: 1204px;
  //overflow: scroll;
}

.filters-container {
  border-radius: 32px;
  background: rgba(32, 34, 37, 0.85);
  backdrop-filter: blur(20px);
}

.filters-container-content {
  margin: 0 8px;
}

.profile-button {
  border-radius: 32px;
  background: #FFF;
  backdrop-filter: blur(20px);
}

.profile-button-notifications-count {
  border-radius: 100px;
  border: 2px solid #FFF;
  background: #020206;
  color: #C3FF01;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  top: 12px;
  left: 26px;
  width: 18px;
  height: 18px;
  text-align: center;
}

.profile-button-notifications {
  gap: 8px;
}

.main-section {
  height: calc(100% - 72px);
  padding: 16px 0;
}

.main-wrapper {
  margin: 26px 24px;
  height: calc(100vh - 52px);
}

.map-search {
  border-radius: 32px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(20px);
  padding: 0 8px;
}

.map-container {
  border-radius: 32px;
  overflow: hidden;
}

.map-wrapper {
  padding-right: 12px !important;
  overflow: hidden;
}

//.col-6.map-wrapper {
//  width: calc(50% - 48px) !important;
//}
//
//.col-9.map-wrapper {
//  width: calc(75% - 36px) !important;
//}

.notifications-list {
  width: 450px;
  top: 0;
  right: 12px;
}

.notification-item {
  border-radius: 32px;
  background: rgba(2, 2, 6, 0.75);
  backdrop-filter: blur(20px);
  min-height: 104px;
}

.close-notifications {
  width: 56px;
  height: 56px;
  border-radius: 24px;
  background: #DFE9F4;
  backdrop-filter: blur(20px);
}

.close-notifications > svg {
  width: 16px;
  height: 100%;
}

.close-notifications > svg > path {
  fill: #77828C;
}

.works-container {
  max-height: 100%;
}

.profile-settings-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 32px;
  background: rgba(32, 34, 37, 0.85);
  backdrop-filter: blur(20px);
  padding: 0 48px 48px 48px;
}

.profile-settings-header {
  height: 72px;
  margin-bottom: 16px;
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 80% */
}

.profile-settings-title {
  gap: 24px;
}

.profile-settings-title-industries {
  gap: 16px;
}

.profile-settings-title-info-name {
  color: #FFF;
  text-align: left;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 80% */
  margin-bottom: 16px;
}

.profile-settings-title-industries {
  color: #C3FF01;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profile-settings-email-title {
  color: #77828D;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
  text-wrap: nowrap;
}

.profile-settings-email-value {
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.profile-settings-content {
  gap: 24px;
}

.dark-user-avatar {
  position: relative;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    background: rgba(2, 2, 6, 0.60);
    z-index: 10;
    left: 0;
    top: 0;
  }
}

.dark-user-avatar-close {
  position: absolute;
  top: 40%;
  left: 40%;
  z-index: 15;
}

.dark-user-avatar-close > path {
  fill: #FFF;
}

.notification-icon {
  min-width: 56px;
}

.notification-icon > svg {
  margin: auto;
}

.portfolio-video {
  width: 196px;
}

.profile-settings {
  height: 100%;
  max-height: 100%;
  position: relative;
}

.profile-settings-title-wrapper {
  max-height: calc(100% - 160px);
  overflow-y: scroll;
  position: absolute;
  top: 88px;
  left: 0;
}

.profile-settings-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}

.profile-settings-logout-btn, .profile-settings-edit-btn, .profile-settings-save-btn {
  margin-top: 16px;
  height: 56px;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(20px);
  padding: 0 24px;
  cursor: pointer;
}

.profile-settings-logout-text, .profile-settings-edit-text, .profile-settings-save-text {
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 80% */
}

.profile-settings-edit-btn {
  background: #FFFFFF;
}

.profile-settings-edit-text {
  color: #020206;
}

.profile-settings-save-btn {
  background: #C3FF01;
}

.profile-settings-save-text {
    color: #020206;
}

.profile-settings-title-wrapper {
  padding-right: 12px;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(2, 2, 6, 0.6);
    border-radius: 100px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #FFFFFF;
    border-radius: 100px;
  }
}

.edit-profile-toggle-container {
  height: 56px;
  width: 300px;
}

.edit-profile-wrapper {
  max-height: calc(100% - 264px);
}

.edit-profile-field-title {
  color: #77828D !important;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal;
  margin-bottom: 16px;
  text-wrap: nowrap;
}

.edit-profile-details-section-title {
  color: #FFF;
  text-align: left;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 80% */
  margin-bottom: 32px;
}

.profile-settings-popup {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 4 !important;
  margin: auto;
}

.profile-settings-popup-container {
  width: 450px;
  max-width: 450px;
  margin-top: 25%;
  padding: 48px;
  border-radius: 32px;
  border: 1px solid #C3FF01;
  background: rgba(2, 2, 6, 0.25);
  backdrop-filter: blur(10px);
}
