
.coming-soon-container {
  background-color: #11121B;
  position: relative;
  min-width: 390px;

  &:before {
    content: '';
    position: absolute;
    min-width: 90%;
    min-height: 90%;
    background: #202225;
    top: 5%;
    right: 5%;
  }

  &:after {
    content: '';
    position: absolute;
    width: 80%;
    height: 80%;
    top: 10%;
    right: 10%;
    background: rgba(147, 192, 0, 0.35);
    filter: blur(137.5px);
  }
}

.coming-soon-center-block {
  max-width: 405px;
  height: 410px;
  z-index: 1;
}

.coming-soon-center-block-logo {
  cursor: pointer;
}

.coming-soon-center-block-logo > div {
  font-family: Urbanist, serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 900;
  color: #FFFFFF;
}

.coming-soon-center-block-logo > svg {
  margin-right: 12px;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.coming-soon-center-block-loading > svg {
  width: 66px;
  height: 66px;
  animation: rotate 10s linear infinite;
}

.coming-soon-center-block-title-container {
  max-width: 330px;
}

.coming-soon-center-block-title-text {
  color: #CEFF32;
  font-family: Urbanist, serif;
  font-size: 64.565px;
  font-style: normal;
  font-weight: 900;
  line-height: 82.5px; /* 127.778% */
  letter-spacing: -1.291px;
}

.coming-soon-center-block-subtitle-text {
  color: #FFF;
  font-family: Urbanist, serif;
  font-size: 28.696px;
  font-style: normal;
  font-weight: 400;
  line-height: 46.63px; /* 162.5% */
}

.coming-soon-center-block-header {
  gap: 32px;
  margin: 0 auto;
  margin: 0 16px;
}


@media only screen and (max-width: 968px) {

  .coming-soon-drone-1, .coming-soon-drone-2 {
    width: 150px;
    height: 150px;
  }
}

.coming-soon-drone-1 {
  top: 0;
  left: 0;
}

.coming-soon-drone-1, .coming-soon-drone-2 {
  width: 270px;
  height: 270px;
}

.coming-soon-drone-2 {
  bottom: 0;
  right: 0;
}

.coming-soon-profile-button {
  right: 0;
  margin: 24px;
}

@media only screen and (max-width: 968px) {

  .coming-soon-drone-1, .coming-soon-drone-2 {
    width: 150px;
    height: 150px;
  }
}

@media only screen and (max-width: 768px) {
  .coming-soon-container > svg {
    width: 128px;
    height: 128px;
  }

  .coming-soon-center-block-logo > svg {
    width: 128px;
    height: 128px;
  }

  .coming-soon-center-block-title-text {
    font-size: 42px;
  }

  .coming-soon-center-block-logo > div {
    font-size: 32px;
  }

  .coming-soon-center-block-subtitle-text {
    font-size: 20px;
  }

  .coming-soon-center-block-logo > svg {
    width: 96px;
    height: 96px;
  }
}
