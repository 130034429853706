.results-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  max-height: 157px;
  overflow-y: auto;
  border-radius: 18px;
  background: rgba(2, 2, 6, 0.60);
  backdrop-filter: blur(20px);
}

.mh-221 {
  max-height: 221px;
}

.mh-72 {
  max-height: 72px;
}

.search-select-bar-wrapper {
  width: 100%;
  height: 56px;
  border-radius: 24px;
  background: rgba(2, 2, 6, 0.60);
  backdrop-filter: blur(20px);
  display: flex;
}

input {
  background-color: transparent;
  border: none;
  height: 100%;
  font-size: 1.25rem;
  width: 100%;
  margin-left: 5px;
}

input:focus {
  outline: none;
}

#search-icon {
  color: royalblue;
}

.search-result {
  padding: 10px 20px;
  cursor: pointer;
  user-select: none;
  height: 56px;
  border-radius: 24px;

  &:hover {
    background-color: #efefef;
    color: black;
  }
}

