@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'Error/NotFound';

@import 'HomePage/HomePage';
@import 'HomePage/CardCarousel/CardCarousel';
@import 'HomePage/HomeHeader/HomeHeader';
@import 'HomePage/RoleInfo/RoleInfo';
@import 'HomePage/HomeHeaderButtons/HomeHeaderButtons';
@import 'HomePage/HomeTitle/HomeTitle';
@import 'HomePage/PricingGetStartedBtn/PricingGetStartedBtn';
@import 'HomePage/HomeFooter/HomeFooter';

@import './AuthPage/AuthPage';
@import './AuthPage/Login/Login';
@import './AuthPage/Signup/Signup';
@import './AuthPage/Signup/DetailsForm/DetailsForm';
@import './AuthPage/Signup/SignupForm/SignupForm';
@import './AuthPage/MainButton/MainButton';
@import './AuthPage/AuthInput/AuthInput';
@import './AuthPage/ForgotPassword/ForgotPassword';
@import './AuthPage/ForgotPassword/ForgotPasswordInputForm/ForgotPasswordInputForm';
@import './AuthPage/ForgotPassword/ForgotPasswordSuccessForm/ForgotPasswordSuccessForm';
@import './AuthPage/ForgotPassword/ForgotPasswordSuccessResetForm/ForgotPasswordSuccessResetForm';
@import './AuthPage/ForgotPassword/ForgotPasswordResetForm/ForgotPasswordResetForm';
@import './AuthPage/AuthSelectField/AuthSelectField';
@import './AuthPage/ActivateAccount/ActivateAccount';
@import './AuthPage/SearchAndSelect/SearchAndSelect';

@import './ComingSoon/ComingSoon';

@import './ToggleButton/ToggleButton';
@import './SmallToggleButton/SmallToggleButton';

@import './Loader/Loader';

@import './MainPage/MainPage';

@import './MainPage/Header/Header';
@import './MainPage/Header/Notification/Notification';

@import './MainPage/Pilot/Contracts/Contracts';
@import './MainPage/Pilot/Invites/Invites';
@import './MainPage/Pilot/Jobs/Jobs';
@import './MainPage/Pilot/Proposals/Proposals';
@import './MainPage/Client/Pilots/Pilots';
@import './MainPage/Client/Pilots/PortfolioCarousel/PortfolioCarousel';
@import './MainPage/Client/Projects/Projects';
@import './MainPage/WorkHeader/WorkHeader';
@import './MainPage/WorkList/WorkList';

@import './CustomButton/CustomButton';

@import './ProfileButton/ProfileButton';
