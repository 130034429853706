
.home_header_buttons {
  justify-content: right;
  gap: 16px;
}

.header__text__content {
  display: flex;
  gap: 16px;
}

.header__text__container {
  height: 100%;
}

.header__text__title {
  color: #020206;
}

.find_pilot_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
