
.carousel_container {
  margin-top: 130px;
}

.carousel_wrapper {
  display: flex;
  justify-content: center;
  gap: 24px;
  height: 229px;
}

.carousel_btn {
  display: flex;
  justify-content: center;
  background-color: #FFFFFF;
  height: 100%;
  min-width: 40px;
  border-radius: 24px;
  cursor: pointer;
}

.carousel_btn_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.carousel_items {
  display: flex;
  max-width: 1096px;
  gap: 24px;
  overflow: hidden;
}

.carousel_card {
  position: relative;
  transition: ease-in-out .2s;
}

.cards_slider {
  background-color: #202225;
  margin: 48px auto 0;
  width: 192px;
  height: 4px;
  border-radius: 32px;
}

.cards_slider_active {
  background-color: #FFFFFF;
  margin: 48px auto 0;
  width: 64px;
  height: 4px;
  border-radius: 32px;
  transition: ease-in-out .2s;
}

.carousel_img {
  border-radius: 32px;
  max-width: 256px;
  min-height: 229px;
}

.carousel_card_title_container {
  position: absolute;
  width: 256px;
  height: 60px;
  left: 0;
  bottom: 0;
  border-radius: 32px;
  display: flex;
  justify-content: center;
  padding: 0 4px;
  background: rgba(2, 2, 6, 0.60);
  backdrop-filter: blur(20px);
  transition: .1s linear;
  color: rgba(255, 255, 255, 0.50);
  font-family: Urbanist, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  &:hover {
    background: #fff;
    color: rgba(2, 2, 6, 0.75);
  }
}

.carousel_card_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slick-arrow {
  display: flex;
  justify-content: center;
  background-color: #FFFFFF !important;
  height: 100%;
  min-width: 40px;
  border-radius: 24px;
  cursor: pointer;

  &:hover {
    background-color: #FFFFFF;
  }

  &:before {
    content: '';
  }
}

.slick-dots {
  bottom: -70px;
}

.slick-dots > li > button:before {
  color: #FFFFFF;
}

.slick-active > button:before {
  color: #FFFFFF !important;
}

.slick-slide > div {
  display: flex;
  justify-content: center;
}

.carousel_card {
  width: unset !important;
}

.slick-prev {
  left: -50px;

  &:before {
    transform: rotate(180deg);
  }
}

.slick-next {
  right: -50px;
}

.slick-prev, .slick-next {
  &:before {
    content: '';
    background: url("../../../resources/icons/short_arrow.svg") no-repeat center;
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
}

@media only screen and (max-width: 430px) {
  .carousel_img {
    max-width: 200px;
    min-height: 180px;
  }

  .carousel_card_title_container {
    width: 200px;
    text-align: center;
  }
}
