
.small_toggle_button_container {
  position: relative;
  width: 46px;
  height: 28px;
  border-radius: 200px;
  background: #FFF;
  cursor: pointer;
  padding-top: 16px;
}

.small_toggle_button_container_active {
  background: #C3FF01;
}

.small_toggle_button_circle {
  position: absolute;
  content: '';
  width: 24px;
  height: 24px;
  border-radius: 200px;
  background: #202225;
  bottom: 2px;
  left: 2px;
  transition: all 0.2s ease;
}

.small_toggle_button_circle_active {
  left: 20px;
  background: #020206;
}
