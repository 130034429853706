
.auth_form_container {
  width: 100%;
  height: 100%;
  border-radius: 32px;
  background: rgba(2, 2, 6, 0.25);
  backdrop-filter: blur(10px);
  margin: auto;
}

.auth_form_content {
  padding: 48px;
}

.auth_form_header {
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.auth_inputs {
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin-bottom: 32px;
}

