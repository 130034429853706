
.auth_input {
  display: flex;
  justify-content: space-between;
  height: 56px;
  border-radius: 24px;
  background: rgba(2, 2, 6, 0.60);
  backdrop-filter: blur(20px);
  padding: 0 16px;
  gap: 16px;
}

.auth_input > span {
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  border-radius: 16px;
  background: #77828D;
  margin: 8px;
}

.auth_input_field_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.auth-input-eye-icon > svg {
  width: 30px;
  cursor: pointer;
}

.auth-input-eye-icon > svg > g {
  fill: #FFFFFF;
}

.auth_input_field {
  all: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 20px;
  width: 100%;
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: left;

  &::placeholder {
    color: #77828C;
  }
}

.auth_input_field_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.auth-input-arrow {
  transition: 0.2s;
}

.auth-input-arrow > svg > path {
  width: 24px;
  height: 24px;
}

.auth-input-arrow > svg > path {
  fill: #FFFFFF;
}
