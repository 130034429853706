
.work-header {
  border-radius: 32px;
  background: rgba(32, 34, 37, 0.85);
  backdrop-filter: blur(20px);
  padding: 8px;
}

.work-header-content {
  height: 56px;
}

.work-header-content > .title {
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px; /* 80% */
}

.scroll-top, .scroll-down, .scroll-left, .scroll-right {
  width: 56px;
  height: 56px;
  border-radius: 24px;
  background: rgba(2, 2, 6, 0.60);
  backdrop-filter: blur(20px);
  display: flex;
  justify-content: center;
  transition: .2s;
  cursor: pointer;

  &:hover {
    background: #FFFFFF;
  }

  &:hover > svg > path {
    fill: black;
  }
}

.scroll-top > svg, .scroll-down > svg, .scroll-left > svg, .scroll-right > svg {
  height: 100%;
}

.scroll-top > svg > path, .scroll-down > svg > path, .scroll-left > svg > path, .scroll-right > svg > path {
  fill: white;
  transition: .2s;
}

.scroll-top > svg {
  rotate: 90deg;
}

.scroll-down > svg {
  rotate: -90deg;
}

.scroll-left > svg {
  rotate: 180deg;
}

.scroll-right > svg {
  rotate: 0deg;
}
