.home_page__wrapper {
  height: 100%;
}

.home_page__content {
  max-width: 1264px;
  height: 100%;
  margin: auto;
  padding: 0 20px;
}

.home_page__background {
  position: relative;
  min-height: 100vh;
}

.home_page__background:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 51%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(45, 45, 45, 1));
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
  background-position-x: center;
  z-index: -3;
}

.background_noise {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 51%;
  filter: contrast(5000%) brightness(20%);
  background-repeat: repeat-y;
  background-size: cover;
  background-position-y: top;
  background-position-x: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7));
  -webkit-mask-size: 100% 100%;
  -webkit-mask-image: -webkit-gradient(linear, center bottom, center top,
          color-stop(0.00, rgba(0, 0, 0, 0)),
          color-stop(1.00, rgba(0, 0, 0, 1)));
  z-index: -1;
}

.bottom_background_noise {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 51%;
  filter: contrast(5000%) brightness(20%);
  background-repeat: repeat-y;
  background-size: cover;
  background-position-y: top;
  background-position-x: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7));
  -webkit-mask-size: 100% 100%;
  -webkit-mask-image: -webkit-gradient(linear, center bottom, center top,
          color-stop(0.00, rgba(0, 0, 0, 0)),
          color-stop(1.00, rgba(0, 0, 0, 1)));
  z-index: -1;
}

.home_page__background:after {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 51%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(45, 45, 45, 1));
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
  background-position-x: center;
  rotate: 180deg;
  z-index: -3;
}

.home_page__gradient {
  position: relative;
}

.home_page__gradient:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(2, 2, 6, 0.25) 0%, rgba(2, 2, 6, 0.00) 100%);
  z-index: -1;
}

.home_page__gradient:after {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(2, 2, 6, 0.25) 0%, rgba(2, 2, 6, 0.00) 100%);
  z-index: -2;
}

.home_page__gradient_background {
  position: relative;
  min-height: 100vh;
}

.home_page__gradient_background:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(11, 13, 16, 0.50) 0%, #0B0D10 100%),
  linear-gradient(rgba(255, 255, 255, 0) -3000%, rgba(1, 1, 1, 1) 10000%),
  url('../../../public/background.png'), #0B0D107F;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-mask-size: 100% 100%;
  -webkit-mask-image: -webkit-gradient(linear, center bottom, center top,
          color-stop(0.00, rgba(0, 0, 0, 0)),
          color-stop(1.00, rgba(0, 0, 0, 1)));
  opacity: 1;
  mix-blend-mode: luminosity;
  z-index: -1;
  max-height: 100vh;
}

.home_page__gradient_background:after {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(11, 13, 16, 0.50) 0%, #0B0D10 100%),
  linear-gradient(rgba(255, 255, 255, 0) -3000%, rgba(1, 1, 1, 1) 10000%),
  url('../../../public/background.png'), #0B0D107F;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-mask-size: 100% 100%;
  -webkit-mask-image: -webkit-gradient(linear, center top, center bottom,
          color-stop(0.00, rgba(0, 0, 0, 0)),
          color-stop(1.00, rgba(0, 0, 0, 1)));
  opacity: 1;
  mix-blend-mode: luminosity;
  z-index: -1;
  max-height: 100vh;
}
