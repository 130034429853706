
.profile-button {
  border-radius: 32px;
  background: #FFF;
  backdrop-filter: blur(20px);
  max-width: 136px;
  height: 72px;
}

.profile-button-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 16px;
  gap: 8px;
}

.profile-button-icon {
  border-radius: 24px;
  background: #4B4B4B;
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.profile-button-arrow > svg {
  transition: 0.2s;
}

.profile-button-username > span {
  color: #020206;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px; /* 100% */
}

.profile-button-dropdown {
  border-radius: 32px;
  overflow: hidden;
  margin-top: 4px;
  gap: 1px;
}

.profile-button-dropdown-item {
  background: #FFF;
  height: 56px;
}

.profile-button-dropdown-item > span {
  color: #020206;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px; /* 100% */
}

@media only screen and (max-width: 768px) {
  .profile-button {
    max-width: 100px;
  }

  .profile-button-username {
    display: none;
  }
}

