.auth_page__wrapper {
  min-width: 320px;
  min-height: 620px;
  overflow: hidden;
}

.auth_page__content {
  max-width: 490px;
  height: 100%;
  margin: auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
}

.auth_page__background {
  position: relative;
  min-height: 100vh;
}

.auth_page__gradient {
  position: relative;
}

.auth_page__gradient:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 150vh;
  background: linear-gradient(180deg, rgba(25, 25, 25, .5) 0%, rgba(5, 5, 5, .5) 100%);
  z-index: -1;
}

.auth_page__gradient_background {
  position: relative;
  min-height: 100vh;
}

.auth_page__gradient_background:before {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  min-height: 200vh;
  background: linear-gradient(180deg, rgba(11, 13, 16, 0.50) 100%, #0B0D10 100%),
  linear-gradient(rgba(255, 255, 255, 0) 100%, rgba(179, 179, 179, 0.9) 100%),
  url('../../../public/background.png'), #0B0D107F;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5;
  mix-blend-mode: luminosity;
  z-index: -1;
}

.auth_page_login_register_switcher {
  width: 100%;
  min-height: 56px;
  display: flex;
  justify-content: space-between;
  margin: 64px 0 24px 0;
  gap: 24px;
  border-radius: 32px;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(6px);
  transition: ease-in-out .2s;

  &::before {
    position: absolute;
    content: '';
    width: 50%;
    min-height: 100%;
    border-radius: 24px;
    background: #020206;
    z-index: -1;
  }
}

.auth_page_login_register_switcher_right::before {
  right: 0;
}


.auth_page_header {
  margin: 64px 0;
  cursor: pointer;
}

.auth_page_header > a > svg {
  margin: 0 4px;

  &:last-child {
    margin-top: 21px;
  }
}

.auth_switch_button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  cursor: pointer;
  flex: 1 1 0;
}

.auth_switch_button_content {
  display: flex;
  justify-content: center;
  gap: 12px;
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px; /* 80% */
  height: 22px;
  margin: 0 8px;
}

.auth_switch_button_content_dashboard {
  color: #020206;
}

.auth_switch_button_content_dashboard_active {
  color: #FFF;
}

.auth_switch_button_text, .auth_switch_button_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
}

.auth_switch_button_icon > svg > path {
  fill: #FFF;
}

.auth_switch_button_icon_dashboard > svg > path {
  fill: #020206;
}

.auth_switch_button_icon_active > svg > path {
  fill: #C3FF01;
}

.auth_page_forms {
  display: flex;
}

.forgot_password_redirect {
  margin: 24px 0;
  text-align: center;
}

.forgot_password_redirect_content {
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  cursor: pointer;
  user-select: none;
}

.login_redirect {
  margin: 24px 0;
  text-align: center;
}

.login_redirect_content {
  color: #77828C;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  user-select: none;
}

.login_redirect_content > span {
  color: #FFFFFF;
  cursor: pointer;
}

.auth_form_small_header {
  color: #FFF;
  text-align: left;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.auth_form_subheader {
  color: #77828C;
  text-align: left;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px;
  margin-top: 32px;
}

.auth_form_subheader > span {
  color: #C3FF01;
  cursor: pointer;

  &:first-child {
    margin-right: 24px;
  }

  &:last-child {
    margin-left: 24px;
  }
}

.auth_form_header_description {
  color: #77828C;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
  margin-top: 24px;
}

.auth_form_body_toggle_fields {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 48px;
}

.auth_form_body_toggle_field {
  height: 55px;
}

.auth_form_body_search_field {
  margin-top: 32px;
}

.search-result, .auth_form_body_search_field > span,
.auth_form_body_search_field > div > span,
.portfolio_upload_photos_count > span,
.auth_form_body_toggle_field > div,
.portfolio_upload_videos_header > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  height: 100%;
}

.search-selected-results > span {
  text-wrap: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  border-radius: 16px;
  background: #77828D;
  margin: 8px;
  min-height: 40px;
}

.search-selected-results > span > span {
  padding: 0 8px;
  margin: auto 0;
}

.search-selected-results {
  min-height: 56px;
  display: flex;
  justify-content: left;
  border-radius: 24px;
  background: rgba(2, 2, 6, 0.60);
  backdrop-filter: blur(20px);
  padding: 0 16px;
  gap: 8px;
}

.auth-page-background_noise {
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: contrast(5000%) brightness(20%);
  background-repeat: repeat-y;
  background-size: cover;
  background-position-y: top;
  background-position-x: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7));
  z-index: -1;
}

.remove-option {
  width: 22px;
  height: 22px;
  border-radius: 4px;

  &:hover {
    background: rgba(1, 1, 1, 0.5);
  }
}
