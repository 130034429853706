
.home_footer {
  display: flex;
  justify-content: space-between;
  padding: 275px 0 55px 0;
}

.rights_reserved {
  color: #FFF;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer_social_links {
  display: flex;
  gap: 8px;
}

.footer_social_link {
  width: 40px;
  height: 40px;
  border-radius: 16px;
  background: #FFF;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.footer_social_link_container {
  display: flex;
  justify-content: center;
}
