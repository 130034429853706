
.auth_form_select_field {
  height: 56px;
  border-radius: 24px;
  background: rgba(2, 2, 6, 0.60);
  backdrop-filter: blur(20px);
  z-index: 1;
}

.auth_form_select_field_button_content {
  display: flex;
  gap: 16px;
  width: 100%;
}

.auth_form_select_field_arrow {
  margin-left: auto;
}

.auth_form_select_field_arrow > svg {
  transform: rotate(90deg);
}

.auth_form_select_field_arrow > svg {
  width: 10px;
  height: 16px;
}

.auth_form_select_field_arrow > svg > path {
  fill: #FFF;
}

.auth_form_select_field_arrow_active > svg > path {
  fill: #C3FF01;
}

.auth_form_select_field_button[aria-expanded="true"] > .auth_form_select_field_button_content > .auth_form_select_field_arrow > svg {
  transform: rotate(270deg);
}

.auth_form_select_field_arrow > svg {
  transition: 0.2s;
}

.auth_form_select_field_options {
  border-radius: 24px;
  overflow: hidden;
  background: rgba(2, 2, 6, 0.60);
  backdrop-filter: blur(20px);
  padding: 0;
}

.auth_form_select_field_options > li {
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: .2s;

  :hover {
    color: black;
  }
}

.auth_form_select_field_options > li > div {
  padding: 0;
  border-radius: 24px;
  height: 100%;
  cursor: pointer;
}

.auth_form_select_field_options > li > div > span {
  padding-left: 20px;
}

ul.auth_form_select_field_options {
  --bs-dropdown-border-radius: 24px;
  --bs-dropdown-item-border-radius: 20px;
  --bs-dropdown-link-active-bg: rgba(30, 30, 30, 0.6);
  --bs-dropdown-item-padding-x: 55px 0;
  user-select: none;
}

.dropdown-item {
  --bs-dropdown-link-color: #FFF;
  --bs-dropdown-link-hover-color: rgba(2, 2, 6, 0.60);
}

.auth_form_select_field_option {
  border-radius: 24px;
  background: rgba(2, 2, 6, 0.60);
  backdrop-filter: blur(20px);
  padding-top: 0;
  border: 24px;
}

.auth_form_select_field_option > div {
  padding: 0 16px;
  gap: 16px;
}

.auth_form_select_field_option > div > svg {
  width: 24px;
  height: 24px;
}

.btn {
  border: none;
}
