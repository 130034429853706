
.loader {
  border: 16px solid #020206;
  border-radius: 50%;
  border-top: 16px solid #f3f3f3;
  width: 120px;
  height: 120px;
  animation: spin 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
