
.toggle-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 32px;
  cursor: pointer;
  user-select: none;
  width: 100%;
  min-height: 56px;
  margin-bottom: 24px;
  gap: 24px;
  border-radius: 32px;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(6px);
  transition: ease-in-out .2s;
  overflow: hidden;
}

.toggle-container-dashboard {
  background: #FFFFFF;
}

.toggle-container-profile {
  border: 2px solid rgba(2, 2, 6, 0.60);
  backdrop-filter: blur(20px);
}

.auth_switch_button_content_profile_inactive {
  color: #77828D;
}

.auth_switch_button_content_profile_inactive > .auth_switch_button_icon > svg > path {
  fill: #77828D;
}

.toggle-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  color: #fff;
  box-shadow: 0 2px 4px rgb(0, 0, 0, 0.25);
  position: absolute;
  transition: all 0.2s ease;
  left: 50%;
  height: 100%;
  width: 50%;
  border-radius: 24px;
  background: #020206;
}

.toggle-btn-dashboard {
  height: 75%;
  margin: 8px auto;
  left: 50%;
  width: calc(50% - 8px);
}

.toggle-btn-disable {
  left: 0;
}

.toggle-btn-dashboard-disable {
  left: 8px;
}
