
.auth_main_button_container {
  background-color: #FFFFFF;
  height: 56px;
  border-radius: 24px;
  backdrop-filter: blur(20px);
  user-select: none;
  cursor: pointer;
}

.auth_main_button_container:active {
  transform: scale(0.98);
  box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}


.auth_main_button_content {
  width: 100%;
  height: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.auth_main_button_text {
  color: #020206;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: 16px; /* 80% */
}
