
.home_title_wrapper {
  max-width: 684px;
  margin: auto;
}

.home_title {
}

.home_title_main_text {
  color: #FFF;
  font-family: Urbanist, serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  align-items: center;
  margin: 86px 0 24px 0;
  text-align: center;
}

.home_title_description_text {
  color: #FFF;
  text-align: center;
  font-family: Urbanist, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 145.455% */
}
