.role_info_container {
  position: relative;
  margin-top: 200px;
  width: 100%;
  border-radius: 64px;
  background: rgba(32, 34, 37, 0.50);
  overflow: hidden;
}

.role_info_container:before {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  border-radius: 32px;
  background: rgba(195, 255, 1, 0.15);
  filter: blur(100px);
  z-index: -1;
}

.role_info_title_container {
  display: flex;
  justify-content: center;
}

.role_info_title_text {
  max-width: 560px;
  color: #FFF;
  text-align: center;
  font-family: Urbanist, serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px; /* 80% */
}

.whoAreYou_img {
  max-width: 462px;
  max-height: 300px;
  resize: vertical;
  border-radius: 32px;
  backdrop-filter: blur(20px);
  margin: auto 0;
}

.mock_role_info_image {
  width: 462px;
  height: 300px;
  resize: vertical;
  border-radius: 32px;
  background: rgba(2, 2, 6, 0.60);
  backdrop-filter: blur(20px);
}

.role_info_section {
  display: flex;
  justify-content: space-between;
}

.role_info_content {
  margin: 70px 100px 100px 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 75px;
}

.role_info_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFF;
  font-family: Urbanist, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: 46px; /* 127.778% */
  letter-spacing: -0.72px;
  max-width: 462px;
  min-width: 350px;
}

.role_info_description {
  color: #FFF;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  margin-top: 12px;
}

.role_info_section {
  margin-top: 75px;
  gap: 20px;
}

.role_info_title {
  width: 400px;
}

.home_role {
  margin-top: 184px;
}

.who_are_you {
  color: #FFF;
  text-align: center;
  font-family: Urbanist, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 111.111% */
  margin-bottom: 64px;
}

.role_buttons {
  display: flex;
  justify-content: space-between;
  gap: 48px;
  margin-top: 64px;
}

.role_button {
  display: flex;
  flex-direction: column;
  width: 588px;
  height: 162px;
  background-color: #FFFFFF;
  border-radius: 64px;
  position: relative;
  overflow: hidden;
  transition: .1s linear;
  cursor: pointer;

  &:hover {
    background: rgb(195, 255, 1);
  }
}

.active-role-button {
  background: #A1D300;

  &:hover {
    background: #A1D300;
  }

  &:after {
    content: '';
    width: 588px;
    height: 162px;
    position: absolute;
    background: #FFFFFF;
    background: repeating-linear-gradient(
                    -45deg,
                    transparent,
                    transparent 4px,
                    transparent 1px,
                    #C3FF0180 7px
    ),
    linear-gradient(
                    to bottom,
                    transparent,
                    transparent
    )
  }
}

.drone_pilot_btn {
}

.drone_pilot_btn:before {
  //content: '';
  //position: absolute;
  //background-color: #fff;
  //background-image: linear-gradient(45deg, #eee .1em, transparent .1em);
  //background-size: 100% 1.2em;
  //width: 100%;
  //height: 100%;
}

.client_btn {
}

.role_btn_content {
  display: flex;
  justify-content: center;
  gap: 32px;
  z-index: 1000;
  margin: 0 10px;
}

.role_option_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 87px;
  height: 25px;
  text-align: center;
  color: #C3FF01;
  font-family: Urbanist, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 0 0 16px 16px;
  background: #202225;
  z-index: 1000;
  padding: 0 10px;
}

.role_btn_header {
  display: flex;
  justify-content: center;
}

.role_btn_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #202225;
  font-family: Urbanist, serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1px;
}

.role_btn_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.role_option_header_text_active {
  color: rgba(255, 255, 255, 0.50);
  font-family: Urbanist, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.role_button_active {
  background: #A1D300;
}

.role_button_active:after {
  //background: #A1D300;
  content: '';
  width: 588px;
  height: 162px;
  position: absolute;
  background: repeating-linear-gradient(
                  -45deg,
                  transparent,
                  transparent 4px,
                  transparent 1px,
                  #C3FF0180 7px
  ),
  linear-gradient(
                  to bottom,
                  transparent,
                  transparent
  );
}

.role_btn_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.not-showing-role {
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 0.5s linear;
}
.showing-role {
  visibility: visible;
  transition: visibility 1s, opacity 0.5s linear;
  opacity: 1;
}

.role-info-main-info {
  display: grid;
  grid-template-columns: 1fr;
}

.role-info-main-info-cards {
  grid-row-start: 1;
  grid-column-start: 1;
}


.process_info_container {
  border-radius: 64px;
  background: rgba(32, 34, 37, 0.50);
  margin-top: 200px;
}

.process_info_content {
  position: relative;
  padding: 75px 100px 100px 100px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.process_info_text {
  text-align: center;
  color: #FFF;
  font-family: Urbanist, serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 80% */
;
}

.process_info_content:before {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 70%;
  border-radius: 32px;
  background: rgba(195, 255, 1, 0.15);
  filter: blur(100px);
  z-index: -1;
}

.process_info_card {
  width: 325px;
  border-radius: 32px;
  background: rgba(2, 2, 6, 0.60);
  backdrop-filter: blur(20px);
  min-width: 250px;
}

.process_info_cards {
  display: flex;
  gap: 24px;
  margin-top: 75px;
}

.process_info_card_header {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.process_info_card_content {
  width: 100%;
  height: 100%;
  padding: 32px 0;
}

.process_info_card_id {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 0 16px 16px 0;
  background: #FFF;
  position: absolute;
  left: 0;
}

.process_info_card_id_content {
  text-align: center;
  color: #202225;
  font-family: Urbanist, serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.process_info_card_title {
  color: #FFF;
  font-family: Urbanist, serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 900;
  line-height: 36px; /* 100% */
  letter-spacing: -0.72px;
  margin-left: 50px;
}

.process_info_card_description {
  padding: 24px 32px 0 32px;
  color: #FFF;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}

.process_info_header {
  margin-bottom: 75px;
}


.find_client_btn {
  position: relative;
  width: 100%;
  height: 260px;
  background: #A1D300;
  margin-top: 200px;
  border-radius: 64px;
  overflow: hidden;
  cursor: pointer;
}

.find_client_btn:before {
  content: '';
  width: 100%;
  height: 273px;
  position: absolute;
  border-radius: 32px;
  background: repeating-linear-gradient(
                  -45deg,
                  transparent,
                  transparent 4px,
                  transparent 1px,
                  #C3FF0180 7px
  ),
  linear-gradient(
                  to bottom,
                  transparent,
                  transparent
  );
}

.find_client_btn_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 0 20px;
}

.find_client_btn_content {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  z-index: 9999;
}

.find_client_btn_arrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;
}

.find_client_btn_second {
  display: flex;
}

.find_client_btn_second_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #020206;
  font-family: Urbanist, serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -1px;
}

.find_client_btn_first {
  color: #FFF;
  font-family: Urbanist, serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -1px;
}

.find_client_btn_arrow > svg {
  width: 34px;
  height: 22px;
}

@media only screen and (max-width: 768px) {
  .find_client_btn_second_text, .find_client_btn_first {
    font-size: 36px;
  }
}

@media only screen and (max-width: 768px) {
  .role_btn_text {
    font-size: 30px;
  }
}

@media only screen and (max-width: 400px) {
  .find_client_btn_first {
    font-size: 30px;
  }

  .find_client_btn_second_text {
    font-size: 30px;
  }
}
