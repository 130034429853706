.password-realtime-validation {
  color: #FFF;
  text-align: left;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: revert;
  gap: 8px;
}
