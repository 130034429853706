
.pricing_button_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 16px;
  background: #FFF;
  cursor: pointer;
  margin-top: auto;
}

.pricing_button_container_active {
  background: #020206;
}

.pricing_button_content {
  display: flex;
  justify-content: center;
  margin: 0 10px;
}

.pricing_button_title {
  margin-right: 16px;
  color: #020206;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.pricing_button_title_active {
  color: #FFFFFF;
}

.pricing_button_icon > svg > path {
  fill: #202225;
}

.pricing_button_icon_active > svg > path {
  fill: #FFFFFF;
}

.pricing_button_icon_active, .pricing_button_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.pricing_info_card {
  position: relative;
}

.pricing_info_card_active {
  position: relative;
  background: #A1D300;
}

.pricing_info_card_active:before {
  content: '';
  width: 100%;
  height: calc(100% - 90px);
  left: 0;
  top: 0;
  position: absolute;
  border-radius: 32px;
  background: repeating-linear-gradient(
                  -45deg,
                  transparent,
                  transparent 4px,
                  transparent 1px,
                  #C3FF0180 7px
  ),
  linear-gradient(
                  to bottom,
                  transparent,
                  transparent
  );
  z-index: -1;
}

.pricing_info_card_price_active {
  color: #FFFFFF;
}

.pricing_info_card_title_active {
  color: #202225;
}

.pricing_info_card_characteristic_text_active {
  color: #202225;
}

.pricing_info_card_subtitle_active {
  color: #202225;
}

.pricing_info_card_characteristic_icon > svg > path {
  fill: #202225;
  fill-opacity: 1;
}