
.portfolio-carousel-img {
  border-radius: 24px;
}

.portfolio-scroll-left {

}

.portfolio-scroll-right {
  right: 0;
}
