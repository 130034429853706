
.details-form-main-button {
  margin-top: 16px;
}


.portfolio_upload_photos_count_arrow > svg {
  width: 24px;
  height: 24px;
}

.portfolio_upload_photos_count_arrow > svg > path {
  fill: #A1D300;
}

.portfolio_upload_photos {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.portfolio_upload_photos_count > span {
  margin-left: 8px;
}

.attach_file_btn_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
}

.attach_file_btn_container > label > input {
  display: none;
  //all: unset;
  color: #C3FF01;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  cursor: pointer;
}

.attach_file_btn_container > label {
  color: #C3FF01;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  cursor: pointer;
}

.portfolio_upload_photos_count {
  display: flex;
}

.portfolio_upload_photos_count_arrow {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.uploaded_file {
  width: 100%;
  height: 40px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(20px);
  display: flex;
  margin-top: 12px;
}

.uploaded_file_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.uploaded_file_icon > svg {
  height: 16px;
  width: 12px;
  margin-left: 12px;
}

.uploaded_file_name {
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-left: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.delete_uploaded_file {
  margin-left: auto;
}

.delete_uploaded_file > svg {
  margin-right: 12px;
  cursor: pointer;
}

.portfolio_upload_videos_header > span {
  margin-top: 32px;
}

.portfolio-upload-videos-embed-button {
  height: 40px;
  border-radius: 16px;
  background: #FFF;
  backdrop-filter: blur(20px);
  cursor: pointer;
}

.portfolio-upload-videos-embed-button-text {
  color: #020206;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Urbanist, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.portfolio-upload-videos-embed-button-icon {
  margin-top: 3px;
}

.portfolio-upload-videos-embed-button-content {
  margin: 0 24px;
  gap: 8px;
}

.delete_video_icon {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
  cursor: pointer;
}

.portfolio_upload_videos {
  margin-bottom: 32px;
}
